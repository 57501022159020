import React from "react";
import { Link } from "react-router-dom";
import { numFormat } from 'src/utils/textUtils';


export const ChintaisIndexView = (props) => {
    const {respons, data:chintais } = props.data.getOrThrow();
    

    return (
        <>
              <ul className="bukkentile-chintai" id="listChintais">
            {
                chintais.map((c, i) => {
                    
                    let src;
                    c.images[0] ? src = `${process.env.REACT_APP_SYSDIR}/${c.images[0].dir}/${c.images[0].name}` 
                    : src = `${process.env.REACT_APP_DIR}/images/noimg.png`

                    return (
                         <li key={i}>
                         <Link to={`/chintaisDetail/${c.id}`}>
                                <figure className="icatch"><img src={src}/></figure>
                                <h3>{c.name}</h3>
                                <div className="overview">
                                    <dl className="full">
                                        <dt>住所</dt>
                                        <dd>{c.address}</dd>
                                    </dl>
                                    <dl>
                                        <dt>間取り</dt>
                                        <dd>{c.search_params.layout}</dd>
                                        <dt>家賃</dt>
                                        <dt>{numFormat(c.price)}円</dt>
                                    </dl>
                                </div>
                            </Link>
                        </li>

                    )
                })
            }
            </ul>
        </>
    )
}