import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { ConfirmView } from "../Views/form/confirmView";
import { SateiFromView } from "../Views/form/sateiFormView";
import styled from "styled-components"
import { Link } from "react-router-dom";

export const KateiPage = () => {
    window.scrollTo(0,0)

    const [input, setInput] = useState(0)
    const [onConfirm, setOnConfrim] = useState(0)

    const { register, formState: { errors }, handleSubmit } = useForm();
    const [actvieTab, setActvieTab] =  useState(1) 

    const onSubmit = (currentInput) => {

        let bukken;
        
        if(actvieTab === 1){
            bukken = [
                {name:'category',label:'物件種別', val:'マンション'},
                {name:'apartName',label:'建物名', val:currentInput.apartName},
                {name:'apartNumber',label:'部屋番号', val:currentInput.apartNumber},
                {name:'apartLayout',label:'間取り', val:currentInput.apartLayout},
                {name:'apartArea',label:'建物面積', val:currentInput.apartArea+currentInput.apartAreaUnit},
                {name:'apartAge',label:'築年数', val:currentInput.apartAge+'年'},
                {name:'apartFloor',label:'階数', val:currentInput.apartFloor+'階'},
            ]
        }else if(actvieTab === 2){
            bukken = [
                {name:'category',label:'物件種別', val:'戸建'},
                {name:'houseLayout',label:'間取り', val:currentInput.houseLayout},
                {name:'houseArea',label:'建物面積', val: currentInput.houseArea+currentInput.houseAreaUnit},
                {name:'houseLandArea',label:'土地面積', val:currentInput.houseLandArea+currentInput.houseLandAreaUnit},
                {name:'houseAge',label:'築年数', val:currentInput.houseAge+'年'},
                {name:'setsudo', label:'接道', val: `巾約${currentInput.loadWidth}m の ${currentInput.loadType}`}
            ]
        }else if(actvieTab === 3){
            bukken = [
                {name:'category',label:'物件種別', val:'土地'},
                {name:'landArea',label:'土地面積', val:currentInput.landArea+currentInput.landAreaUnit},
                {name:'setsudo', label:'接道', val: `巾約${currentInput.loadWidth}m の ${currentInput.loadType}`}
            ]
        }else{}

        const customer = [  
            {name:'bukkenAddress',label:'物件住所', val:currentInput.bukkenAddress},
            {name:'genkyo',label:'現況', val:currentInput.genkyo},
            {name:'note',label:'備考', val:currentInput.note},
            {name:'name', label:'お名前', val:currentInput.name},
            {name:'address', label:'住所', val:currentInput.address},
            {name:'tel',label:'電話番号', val:currentInput.tel},
            {name:'email',label:'メールアドレス', val:currentInput.email},
        ]

        setInput(bukken.concat(customer))
        setOnConfrim(1)
    }
    return (
        <>
        {  onConfirm === 1 && 
        <section id="contents" className="contents">
            <div className="satei-inner">
                <SHeadline className="pagetitle-satei">買取・依頼フォーム</SHeadline>
                <ConfirmView  {...{input, setOnConfrim, type:'satei'}}></ConfirmView>
            </div>
        </section>
        }
        {onConfirm == 0 &&
        <section id="contents" className="contents">
            <div className="satei-inner">
                <SHeadline className="pagetitle-satei">買取・依頼フォーム</SHeadline>
                <p className="txt">
                    弊社買取価格をお客様にご検討して頂き承諾して頂いた場合に限りご契約をさせて頂きます。<br/>
                    物件調査ののち売買契約締結後約２週間後残金取引を行います。
                </p>
                <p className="txt">
                     <Link to={`/satei`} className="btn-bacis-large">査定はこちら</Link>
                </p>
                <SateiFromView {...{
                    onSubmit,
                    register,
                    errors,
                    handleSubmit,
                    setActvieTab
                }}></SateiFromView>
            </div>
        </section>
        }
        </>
    )
}

const SHeadline = styled.h1`
    background: #292AFF;
    color: #fff;
`