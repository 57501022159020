import React from "react";
import { textToBr } from 'src/utils/textUtils';
import parse from 'html-react-parser';
import { formattedYearMonth } from 'src/utils/dateTime';

export const LandColumnParts = ({detail}) => {

    const colums = [
        {key: 'address', name: '住所'},
        {key: 'land_category', name: '地目'},
        {key: 'use_district', name: '用途地域'},
        {key: 'floor_area_ratio', name: '建ぺい率･容積率'},
        {key: 'land_area', name: '土地面積'},
        {key: 'tubo', name: '坪数'},
        {key: 'total_sales', name: '販売区画数'},
        {key: 'total_area', name: '総区画数'},
        {key: 'private_road', name: '私道負担・道路'},
        {key: 'land_status', name: '土地状況'},
        {key: 'traffic', name: '路線・バス'},
        {key: 'land_rights', name: '土地の権利形態'},
        {key: 'building_criteria', name: '建築条件'},
        {key: 'dealing_time', name: '引き渡し時期'},
        {key: 'earthwork_completion_date', name: '造成完了時期'},
        {key: 'city_plan', name: '都市計画'},
        {key: 'current', name: '現況'},
        {key: 'waterway', name: '上下水道'},
        {key: 'surroundings', name: '周辺環境'},
        {key: 'school', name: '学校名'},
        {key: 'school_traffic', name: '学校までの距離'},
        {key: 'deal_type', name: '取引形態'},
        {key: 'other_limit_matter', name: 'その他制限事項'},
        {key: 'other_overview_notice', name: 'その他概要・特記事項'},
    ]

    return (
        <>
            
                { 
                colums.map((c, i) => {
                    if(detail[c.key] && (detail[c.key] != 'undefined' && detail[c.key] != 'null')){
                        return (
                            <dl key={i}>
                                <dt>{c.name}</dt>
                                <dd>
                                    {
                                        c.key === 'built_year_month' ? formattedYearMonth(detail[c.key]) : 
                                                    (typeof detail[c.key] === 'string' ? parse(textToBr(detail[c.key])) : detail[c.key])
                                        
                                    }
                                </dd>
                            </dl>
                        )
                    }else{}
                })
                }
        
        </>
    )
}