import React, { Suspense, useEffect, useState } from "react";
import { Useable } from 'src/utils/Useable';
import { fetchLandIndex } from "src/data/fetchLand";
import { Loader } from "src/components/Parts/loaderParts";
import { LandsIndexView } from "../Views/landsIndexView";


export const LandsPage = () => {
    document.title = '土地情報 | 有限会社 太洋開発 宮崎県日向市'

    const [lands, setLands] = useState(0)

    useEffect(() => {
        setLands(new Useable(fetchLandIndex()))
    },[])

    return (
        <>
       <section id="contents" className="contents">
            <div className="chitnais-inner">
                <h1 className="pagetitle-land">
                    土地を探す
                </h1>
                {lands &&
                    <Suspense fallback={<Loader></Loader>}>
                        <LandsIndexView {...{data: lands}}></LandsIndexView>
                    </Suspense>
                }
            </div>
        </section>
        </>
    )
}