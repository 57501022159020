import React from "react";
import { Link } from "react-router-dom";
import { addComma } from 'src/utils/textUtils';
import parse from 'html-react-parser';
import { numFormat } from 'src/utils/textUtils';

export const BaibaisIndexView = (props) => {

    const {respons, data:baibais } = props.data.getOrThrow();
    
    
    return (
        <>
          <ul className="bukkentile-baibai" id="listBaibai">
            {       
                
                baibais.map((c, i) => {
                    let src;
                    c.images[0] ? src = `${process.env.REACT_APP_SYSDIR}/${c.images[0].dir}/${c.images[0].name}` 
                                : src = `${process.env.REACT_APP_DIR}/images/noimg.png`

                    return (
                        
                        <li key={i}>
                            <Link to={`/baibaisDetail/${c.id}`}>
                                <figure className="icatch"><img src={src}/></figure>
                                <h3>{c.name}</h3>
                                <div className="overview">
                                    <dl className="full">
                                        <dt>住所</dt>
                                        <dd>{c.address}</dd>
                                    </dl>
                                    <dl>
                                        <dt>土地面積</dt>
                                        <dd>{c.land_area}</dd>
                                    </dl>
                                    <dl>
                                        <dt>建物面積</dt>
                                        <dd>{c.building_area}</dd>
                                    </dl>
                                   
                                </div>
                                <div className="overview">
                                    <dl>
                                        <dt>価格</dt>
                                        <dd>{numFormat(c.price)}</dd>
                                    </dl>
                                </div>
                            </Link>
                            </li>
                    )
                })
            }
                
            </ul>
        </>
    )
}