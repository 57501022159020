import React from "react";
import { useForm } from 'react-hook-form';


export const BukkenFormView = (props) => {
    const { register, formState: { errors }, handleSubmit } = useForm();

    const onSubmit = (currentInput) => {
        props.setInput(
            [   
                {name:'bukken', label:'お問い合わせ物件', val:props.name},
                {name:'bukkenAddress', label:'物件住所', val:props.address},
                {name:'bukkenUrl', label:'お問い合わせ物件URL', val: `${process.env.REACT_APP_URL}/${props.dir}/${props.id}`},
                {name:'name',label:'お名前', val:currentInput.name},
                {name:'address', label:'住所', val:currentInput.address},
                {name:'tel',label:'電話番号', val:currentInput.tel},
                {name:'email',label:'メールアドレス', val:currentInput.email},
                {name:'message',label:'お問合せ内容', val:currentInput.message},
            ]
        )
        props.setOnConfrim(1)
    }

    return (
        <section id="indform">
            <h1 className="indform-title-chintai">
                この物件のお問い合せ
            </h1>
            <div className="indform-inner">   
                <form className="form" onSubmit={handleSubmit(onSubmit)} method="post">
                    <table className="indform-table">
                        <tbody>
                        <tr>
                            <th><label>お問合せ内容</label>
                                <span className="requir">必須</span>
                                <p>※見学希望の場合は、希望日時等</p>
                            </th>
                            <td>
                                <div className="f-textarea"><textarea  {...register("message",{ required:true })} className="f-hsize-4"></textarea></div>
                                <span id="message_err" className={errors.message && "err_on"}>{errors.message && "エラー：未入力"}</span>
                            </td>
                        </tr>
                        <tr>
                            <th><label>お名前</label>
                                <span className="requir">必須</span>
                            </th>
                            <td>
                                <input type="text" name="name" {...register("name",{ required:true })} className="f-size-7"/>
                                <span id="name_err" className={errors.name && "err_on"}>{errors.name && "エラー：未入力"}</span>
                            </td>
                        </tr>
                        <tr>
                            <th><label>現住所</label>
                                <span className="requir">必須</span>
                            </th>
                            <td>
                                 <input type="text"  name="address"　{...register("address",{ required:true })}  className="f-size-7"/>
                            <span id="name_err" className={errors.address && "err_on"}>{errors.address && "エラー：未入力"}</span>
                            </td>
                        </tr>
                        <tr>
                            <th><label>メールアドレス</label>
                                <span className="requir">必須</span>
                            </th>
                            <td>
                                <input type="text" 
                                                {...register("email",{ required:true, pattern:/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/ })}
                                             className="f-size-7" placeholder="メールアドレス"/>
                                <span id="email_err" className={errors.email && "err_on"}>
                                    {errors.email?.type === "required" && "エラー：未入力"}
                                    {errors.email?.type === "pattern" && "エラー：形式が違います"}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th><label>電話番号</label>
                                <span className="requir">必須</span>
                            </th>
                            <td>
                                <input type="text" id="f-tel" className="f-size-7" {...register("tel",{ required:true })}/>
                                <span id="tel_err" className={errors.tel && "err_on"}>{errors.tel && "エラー：未入力"}</span>    
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div className="agree">
                        <div className="f-size-auto"><div className="f-checkbox"><input type="checkbox" id="agree"  {...register("privacy",{ required:true })}/><label htmlFor="agree">個人情報の取扱に同意する</label></div></div>
                    </div>
                    <button type="submit" className="indform-submit">確認画面へ</button>
                </form>
            </div>
        </section>
        )

}

