import React, { useState, Suspense } from "react";
import { Link } from "react-router-dom";
import { fetchContact, fetchBukken, fetchSatei } from 'src/data/fetchContact';
import { Useable } from 'src/utils/Useable';
import { useForm } from 'react-hook-form'
import styled from "styled-components"

const CompSend = (props) => {

    console.log( props.data.getOrThrow())
    return (
        <>
             <div className="contact-comp-msg">
                <p>お問い合わせいただきありがとうございます。<br/>
                ご入力いただいた内容を受け付けました。<br/>
                後日、担当者よりご連絡差し上げますので、今しばらくお待ちください。<br/>
                </p>
                <Link to={`/`} className="btn">TOPヘ戻る</Link>
            </div>
        </>
    )
}

export const ConfirmView = (props) => {

    const inputs =  props.input;
    const [onSend, setOnSend] = useState(0);
    const formData = new FormData()

    const { handleSubmit, register } = useForm();

    const onSubmit = (currentInput) => {
        if(props.type === 'contact'){
            formData.append('name',currentInput.name)
            formData.append('company',currentInput.company)
            formData.append('name',currentInput.name)
            formData.append('tel',currentInput.tel)
            formData.append('email',currentInput.email)
            formData.append('message',currentInput.message)
            setOnSend(new Useable(
                fetchContact(formData)
            ))
        }else if(props.type === 'bukken'){
            formData.append('name',currentInput.name)
            formData.append('bukken',currentInput.bukken)
            formData.append('bukkenAddress',currentInput.bukkenAddress)
            formData.append('bukkenUrl',currentInput.bukkenUrl)
            formData.append('address',currentInput.address)
            formData.append('tel',currentInput.tel)
            formData.append('email',currentInput.email)
            formData.append('message',currentInput.message)
            setOnSend(new Useable(
                fetchBukken(formData)
            ))
        }else if(props.type === 'satei'){
            Object.keys(currentInput).forEach(key => {
                const val = currentInput[key];
                console.log(`${key} >>> ${val}`)
                formData.append(key,val)
            });
            setOnSend(new Useable(
                fetchSatei(formData)
            ))
        }
    }
    console.log(onSend)

    return (
        <div className="indform-inner">   
        {onSend === 0 &&
        <>
            <form className="form" id="f-form" onSubmit={handleSubmit(onSubmit)}>
                <SDl className="indform-table">
                    {
                        inputs.map((p,i) => {
                            return(
                                <>
                                    <dt><label>{p.label}</label></dt>
                                    <dd> 
                                        {p.val}
                                        <input type="hidden" {...register(p.name)} value={p.val}/>
                                    </dd>
                                </>
                            )
                        })
                    }
                </SDl>
                <button type="submit" className="indform-submit">送信する</button>
            </form>
            
            <SBtn onClick={() => {props.setOnConfrim(0)}} className="btn"> ＜ 戻る</SBtn>
        </>
        }
        {onSend !== 0 &&
            <Suspense>
                <CompSend {...{data:onSend}}></CompSend>
            </Suspense>
        }
       </div>
    )
}

const SBtn = styled.button`
    cursol:poniter;
`
const SDl = styled.dl`
    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
    padding: 14px;
    border: solid 1px #cecccc;
    background:#fff;
    dt{
        box-sizing: border-box;
        flex-basis: 30%;
        padding: 20px;
        background: #F7F7F7;;
        border-bottom: 2px solid #fff;
        display: flex;
        align-items: center;
    }
    dd{
        box-sizing: border-box;
        flex-basis: 70%;
        padding: 20px;
        border-bottom: 2px solid #F7F7F7;;
    }
`