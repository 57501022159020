import React,{useState} from "react";
import { ChintaiColumnParts } from "../Parts/chintaiColumnsParts";
import { SlideGallaryParts } from "../Parts/slideGallary/slideGallaryParts";
import parse from 'html-react-parser';
import { textToBr, numFormat } from 'src/utils/textUtils';
import { formattedYearMonth } from 'src/utils/dateTime';
import { Link } from "react-router-dom";
import { BukkenFormView } from "./form/bukkenFormView";
import { ConfirmView } from "./form/confirmView";
import { SlickSliderParts } from "../Parts/slideGallary/slickSliderPrats";

export const ChintaisDetailView = (props) => {
    
    const {respons, data: detail} = props.data.getOrThrow()
    
    respons === 'success' ? document.title = `${detail.name} - ${detail.address} | 賃貸物件  | 有限会社 太洋開発 宮崎県日向市`
                            : document.title = respons
    
    
    const [input, setInput] = useState(0)
    const [onConfrim, setOnConfrim] = useState(0)

    return (
        <>
        { respons === 'success'  &&  
            onConfrim === 0 &&
        <div>
             <section id="detail">
                <div className="detail-inner">
                    <Link to={`/chintais`} className="pageback">一覧に戻る</Link>
                    <h1 className="detail-title-chintai"><span> {detail.search_params.category}</span> <strong>{detail.name}</strong></h1>
                    <div className="detail-headline">
                        <dl>
                            <dt>家賃</dt>
                            <dd>{numFormat(detail.price)}円</dd>
                        </dl>
                        <dl>
                            <dt>住所</dt>
                            <dd>{detail.address}</dd>
                        </dl>
                    </div>
                    <div className="detail-main">
                        <div className="visual">
                            <div className="photos mb-20">
                            { detail.images.length > 0 &&
                                <SlickSliderParts {...{images:detail.images}}></SlickSliderParts>
                            }
                             </div>
                             <div className="photos">
                             { detail.madoris.length > 0 &&
                                <SlickSliderParts {...{images:detail.madoris}}></SlickSliderParts>
                                }
                             </div>
                             <p className="notes">
                            {parse(textToBr(detail.summary))}
                            </p>
                        </div>
                       
                        <div className="columns">
                            <ChintaiColumnParts {...{detail}}></ChintaiColumnParts>
                        </div>
                    </div>
                </div>
            </section>
            <section id="photoMap">
                <div className="photoMap-inner">
                    <h1 className="photoMap-title-chintai">地図</h1>
      
                    <div className="photoMap-map">
                        {parse(detail.gmap)}
                    </div>
                </div>
            </section>
            <BukkenFormView {...{setOnConfrim, 
                setInput, 
                dir:'chintaisDetail', 
                address:detail.address,
                id:detail.id, 
                name:detail.name}}>    
                </BukkenFormView>
        </div>
        }
        { respons === 'success'  &&  
            onConfrim === 1 &&
            <section id="indform">
            <h1 className="indform-title-chintai">
                お問い合わせフォーム
            </h1>
            <ConfirmView {...{input, setOnConfrim, type:'bukken'}}></ConfirmView>
            </section>
        }
        </>
    )
}