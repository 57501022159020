import React from "react";
import styled from "styled-components"

export const ApartmentForm = ({register, errors}) => {

    return (
    <div className="item" data-tab="1">
        <dl>
            <dt>マンション名</dt>
            <dd> 
                <input type="text"  {...register("apartName",{ required:true })} />
                <span className={errors.apartName && "err_on"}>{errors.apartName && "エラー：未入力"}</span>
            </dd>
            <dt>部屋番号</dt>
            <dd> 
                <input type="text"  {...register("apartNumber",{ required:true })} />
                <span className={errors.apartNumber && "err_on"}>{errors.apartNumber && "エラー：未入力"}</span>
            </dd>
            <dt>間取り</dt>
            <dd>
                <SSelectWrap   {...register("apartLayout",{ required:true })}>
                    <SSelect>
                        <option value="ワンルーム・1K">ワンルーム・1K</option>
                        <option value="1K・1DLK">1DK・1DLK</option>
                        <option value="2DK・2DLK">2DK・2DLK</option>
                        <option value="3DK・3DLK">3DK・3DLK</option>
                        <option value="4DK・4DLK">4DK・4DLK</option>
                        <option value="5DK・5DLK 以上">5DK・5DLK 以上</option>
                        <option value="その他">その他</option>
                    </SSelect>
                </SSelectWrap>
            </dd>
            <dt>建物面積</dt>
            <dd>
                <div> <input type="text" name=""  {...register("apartArea",{ required:true })}/></div>
                <span className={errors.apartArea && "err_on"}>{errors.apartArea && "エラー：未入力"}</span>
                <SLabel><SRadio type="radio" {...register("apartAreaUnit")} value="坪" checked /><SSpan className="outside"><SSpan className="inside" ></SSpan></SSpan>坪</SLabel>
                <SLabel ><SRadio type="radio"{...register("apartAreaUnit")} value="㎡" /><SSpan className="outside"><SSpan className="inside" ></SSpan></SSpan>㎡</SLabel>
            </dd>
            <dt>築年数</dt>
            <dd>
                <input type="text" {...register("apartAge",{ required:true })} className="f-size-2"/> 年
                <span className={errors.apartAge && "err_on"}>{errors.apartAge && "エラー：未入力"}</span>
            </dd>
            <dt>階数</dt>
            <dd>
                <input type="text" {...register("apartFloor",{ required:true })} className="f-size-2"/> 階
                <span className={errors.apartFloor && "err_on"}>{errors.apartFloor && "エラー：未入力"}</span>
            </dd>
        </dl>
    </div>
    )
}


export const HouseForm = ({register, errors}) => {

    return (
        <div className="item" data-tab="2">
            <dl>
                <dt>間取り</dt>
                <dd>
                    <SSelectWrap>
                        <SSelect  {...register("houseLayout",{ required:true })}>
                            <option value="ワンルーム・1K">ワンルーム・1K</option>
                            <option value="1K・1DLK">1DK・1DLK</option>
                            <option value="2DK・2DLK">2DK・2DLK</option>
                            <option value="3DK・3DLK">3DK・3DLK</option>
                            <option value="4DK・4DLK">4DK・4DLK</option>
                            <option value="5DK・5DLK 以上">5DK・5DLK 以上</option>
                            <option value="その他">その他</option>
                        </SSelect>
                    </SSelectWrap>
                </dd>
                <dt>建物面積</dt>
                <dd>
                    <div> <input type="text" name=""  {...register("houseArea",{ required:true })}/></div>
                    <span className={errors.houseArea && "err_on"}>{errors.houseArea && "エラー：未入力"}</span>
                    <SLabel><SRadio type="radio"  value="坪" checked  {...register("houseAreaUnit")}/><SSpan className="outside"><SSpan className="inside"></SSpan></SSpan>坪</SLabel>
                    <SLabel ><SRadio type="radio"  value="㎡"  {...register("houseAreaUnit")}/><SSpan className="outside"><SSpan className="inside"></SSpan></SSpan>㎡</SLabel>
                </dd>
                <dt>土地面積</dt>
                <dd>
                <div> 
                    <input type="text" name=""  {...register("houseLandArea",{ required:true })}/></div>
                    <span className={errors.houseLandArea && "err_on"}>{errors.houseLandArea && "エラー：未入力"}</span>
                    <SLabel><SRadio type="radio"  value="坪" checked  {...register("houseLandAreaUnit")}/><SSpan className="outside"><SSpan className="inside"></SSpan></SSpan>坪</SLabel>
                    <SLabel ><SRadio type="radio"  value="㎡"  {...register("houseLandAreaUnit")}/><SSpan className="outside"><SSpan className="inside"></SSpan></SSpan>㎡</SLabel>
                </dd>
                <dt>築年数</dt>
                <dd>
                    <input type="text" {...register("houseAge",{ required:true })} className="f-size-2"/> 年
                    <span className={errors.houseAge && "err_on"}>{errors.houseAge && "エラー：未入力"}</span>
                </dd>
                <dt>接道</dt>
                <dd>
                    <div> 巾約 <input type="text" name="loadWidth"  className="w200" {...register("loadWidth")} /> m </div>
                    <SSelectWrap className="selectWrap m-t-20">
                        <SSelect name="loadType"  {...register("loadType")}>
                            <option value="公道">公道</option>
                            <option value="私道">私道</option>
                        </SSelect>
                    </SSelectWrap>
                </dd>
            </dl>
        </div>
    )
}

export const LandForm = ({register, errors}) => {

    return (
        <div className="item" data-tab="3">
            <dl>
                <dt>土地面積</dt>
                <dd>
                    <div> <input type="text"  {...register("landArea",{ required:true })}/></div>
                    <span className={errors.landArea && "err_on"}>{errors.landArea && "エラー：未入力"}</span>
                    <SLabel><SRadio type="radio"  value="坪" checked  {...register("landAreaUnit")}/><SSpan className="outside"><SSpan className="inside"></SSpan></SSpan>坪</SLabel>
                    <SLabel ><SRadio type="radio"  value="㎡"  {...register("landAreaUnit")}/><SSpan className="outside"><SSpan className="inside"></SSpan></SSpan>㎡</SLabel>
                </dd>
                <dt>接道</dt>
                <dd>
                    <div> 巾約 <input type="text" name="loadWidth"  className="w200" {...register("loadWidth")} /> m </div>
                    <SSelectWrap className="selectWrap m-t-20">
                        <SSelect name="loadType"  {...register("loadType")}>
                            <option value="公道">公道</option>
                            <option value="私道">私道</option>
                        </SSelect>
                    </SSelectWrap>
                </dd>
            </dl>
        </div>
    )

}

const SLabel = styled.label`
    margin: 4px;
    display: inline-block;
    position: relative;
    padding-left: 40px;
    cursor: pointer;
    background: var(--color-gray);
    height: 50px;
    display: flex;
    align-items: center;
    border-radius: 5px;
`;

const SRadio = styled.input`
    height: 1px;
    width: 1px;
    opacity: 0;
    &:checked + .outside .inside{
        animation: radio-select 0.1s linear;
        transform: scale(1, 1);
    }
`;



const SSpan = styled.span`
    &.inside{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        background: var(--color-blue);
        left: 3px;
        top: 3px;
        transform: scale(0, 0);
    }
    &.outside {
        display: inline-block;
        position: absolute;
        left: 4px;
        top: 50%;
        margin-top: -15px;
        width: 30px;
        height: 30px;
        border: 2px solid #CCCCCC;
        border-radius: 50%;
        box-sizing: border-box;
        background: #F3F3F3;
      }
`
const SSelect = styled.select`
    border: solid #e7e7e7 !important;
    position: relative;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
`

const SSelectWrap = styled.div`
    position: relative;
      &::after{
        content: "";
        position: absolute;
        right: 10px;
        top: 15px;
        width: 10px;
        height: 10px;
        border-top: 2px solid #444;
        border-left: 2px solid #444;
        transform: translateY(-50%) rotate(-135deg);
        font-size: 20px;
        pointer-events: none;
        z-index: 999;
      }
`

