import React, { useState } from "react";
import { useForm } from 'react-hook-form';
import { ConfirmView } from "../Views/form/confirmView";


export const ContacPage = () => {
    document.title = 'お問合せ | 有限会社 太洋開発 宮崎県日向市'
    window.scrollTo({ top: 0});
    
    const type = 'contact'

    const { register, formState: { errors }, handleSubmit, setError, clearErrors, getValues } = useForm();
    
    const [input, setInput] = useState(0)
    const [onConfrim, setOnConfrim] = useState(0)

    const onSubmit = (currentInput) => {
        setInput(
            [
                {name:'message',label:'お問合せ内容', val:currentInput.message},
                {name:'name',label:'お名前', val:currentInput.name},
                {name:'company', label:'会社名', val:currentInput.company},
                {name:'email',label:'メールアドレス', val:currentInput.email},
                {name:'tel',label:'電話番号', val:currentInput.tel},
            ]
        )
        setOnConfrim(1)
    }
    return (
        <section id="contents">
        <div className="infomation-inner">  
            <div className="infomation-tel">
                <h3>電話でのお問い合わせ</h3>
                <a className="tel_data"  href="tel:0982-52-0290" data-tel="0982-52-0290" id="contact_tel">☎︎0982-52-0290</a>
                <dl>
                    <dt>営業時間</dt>
                    <dd>9：00～18：00 <br/>第1・第3日曜日 9：00～15：00</dd>
                    <dt>定休日</dt>
                    <dd>祭日、第2・第4日曜日　年末年始・夏季休暇</dd>
                </dl>
            </div>
            <h1 className="pagetitle-infomation">お問い合せフォーム</h1>

            {onConfrim === 0 &&
            <form className="form" onSubmit={handleSubmit(onSubmit)} method="post">
                <table className="indform-table">
                    <tbody>
                    <tr>
                        <th><label>お問合せ内容</label>
                            <span className="requir">必須</span>
                        </th>
                        <td>
                            <div className="f-textarea"><textarea  {...register("message",{ required:true })} className="f-hsize-4"></textarea></div>
                            <span id="message_err" className={errors.message && "err_on"}>{errors.message && "エラー：未入力"}</span>
                        </td>
                    </tr>
                    <tr>
                        <th><label>お名前</label>
                            <span className="requir">必須</span>
                        </th>
                        <td>
                            <input type="text" name="name" {...register("name",{ required:true })} className="f-size-7"/>
                            <span id="name_err" className={errors.name && "err_on"}>{errors.name && "エラー：未入力"}</span>
                        </td>
                    </tr>
                    <tr>
                        <th><label>会社名</label>
                        </th>
                        <td>
                                <input type="text"  name="company"　{...register("company",{ required:false })}  className="f-size-7"/>
                        </td>
                    </tr>
                    <tr>
                        <th><label>メールアドレス</label>
                            <span className="requir">必須</span>
                        </th>
                        <td>
                            <input type="text" 
                                            {...register("email",{ required:true, pattern:/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/ })}
                                            className="f-size-7" placeholder="メールアドレス"/>
                            <span id="email_err" className={errors.email && "err_on"}>
                                {errors.email?.type === "required" && "エラー：未入力"}
                                {errors.email?.type === "pattern" && "エラー：形式が違います"}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <th><label>電話番号</label>
                            <span className="requir">必須</span>
                        </th>
                        <td>
                            <input type="text" id="f-tel" className="f-size-7" {...register("tel",{ required:true })}/>
                            <span id="tel_err" className={errors.tel && "err_on"}>{errors.tel && "エラー：未入力"}</span>    
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div className="agree">
                    <div className="f-size-auto"><div className="f-checkbox"><input type="checkbox" id="agree"  {...register("privacy",{ required:true })}/><label htmlFor="agree">個人情報の取扱に同意する</label></div></div>
                </div>
                <button type="submit" className="indform-submit">確認画面へ</button>
            </form>
            }       
             {onConfrim !== 0&&
                <ConfirmView {...{input, setOnConfrim, type}}></ConfirmView>
             }
        </div>
    </section>
    )
}