import React, { Suspense, useEffect, useState } from "react";
import { Useable } from 'src/utils/Useable';
import { fetchBaibaiIndex } from "src/data/fetchBaibai";
import { Loader } from "src/components/Parts/loaderParts";
import { BaibaisIndexView } from "../Views/baibaisIndexView";


export const BaibaisPage = () => {
    document.title = '新築・中古物件 | 有限会社 太洋開発 宮崎県日向市'
    const [baibais, setBaibais] = useState(0)

    useEffect(() => {
        setBaibais(new Useable(fetchBaibaiIndex()))
    },[])

    return (
        <>
      <section id="contents" className="contents">
        <div className="chitnais-inner">
            <h1 className="pagetitle-baibai">
                新築・中古を探す
            </h1>
                {baibais && 
                <Suspense fallback={<Loader></Loader>}>
                    <BaibaisIndexView {...{data:baibais}}></BaibaisIndexView>
                </Suspense>
                }
            </div>
        </section>
        </>
    )
}