import React from "react";
import { Link } from "react-router-dom";
import { addComma } from 'src/utils/textUtils';
import parse from 'html-react-parser';
import { numFormat } from 'src/utils/textUtils';


export const LandsIndexView = (props) => {

    const {respons, data:lands } = props.data.getOrThrow();
    
    
    return (
        <>
             <ul className="bukkentile-land" id="listLands">
                {
                    lands.map((c, i) => {
                        let src;
                        c.images[0] ? src = `${process.env.REACT_APP_SYSDIR}/${c.images[0].dir}/${c.images[0].name}` 
                                        : src = `${process.env.REACT_APP_DIR}/images/noimg.png`
                                        
                        return (
                            <li key={i}>
                            <Link to={`/landsDetail/${c.id}`}>
                                <figure className="icatch"><img src={src}/></figure>
                                <h3>{c.name}</h3>
                                <div className="overview">
                                    <dl className="full">
                                        <dt>住所</dt>
                                        <dd>{c.address}</dd>
                                    </dl>
                                    <dl>
                                        <dt>坪数</dt>
                                        <dd>{c.tubo}</dd>
                                        <dt>価格</dt>
                                        <dt>{numFormat(c.price)}</dt>
                                    </dl>
                                </div>
                            </Link>
                            </li>
                        )
                    })
                    
                }   
            </ul>
        </>
    )
}