import React, { memo, useRef, useState } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './image-gallary-custom.css'
import Modal from 'react-modal';

// カスタムPrevボタンのコンポーネント
const ModalImg = ({imgSrc}) => {
    // ウィンドウの高さを取得
    const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;


    return(
        <img src={imgSrc} style={{maxHeight:`${windowHeight*0.8}px`}} />
    )
}

export const SlickSliderParts = ({ images }) => {
  
    const slider1 = useRef();
    const slider2 = useRef();
    const [currentSlide, setCurrentSlide] = useState(0);

    const settings1 = {
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: slider2.current,
        infinite: true,
    };

    const settings2 = {
        slidesToShow: images.length > 5 ? 6 : images.length ,
        slidesToScroll: 1,
        asNavFor: slider1.current,
        focusOnSelect: true,
        arrows:false,
        beforeChange: (current, next) => {
            setCurrentSlide(next);
          },
    };

    const [modalIsOpen, setIsOpen] = useState(false);
    const [imgSrc, setImgSrc] = useState('')

    const openModal = (event) => {
        const clickedElement = event.target;
        if (clickedElement.tagName === "IMG") {
            setImgSrc(clickedElement.getAttribute("src"));
            setIsOpen(true);
        }
      
    }
    const closeModal = () => {
        setIsOpen(false);
    }   

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          maxHeight:'90%',
          zIndex:1000,
        },
    };

    Modal.setAppElement('#root');
    return (
        <div>
             <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <ModalImg {...{imgSrc}}></ModalImg>
            </Modal>

            <Slider
                className="slider-for"
                {...settings1}
                ref={slider1}
            >
                {images.map((img, i) => (
                <div key={img.name} onClick={openModal}>
                    <figure>
                        <img src={`${process.env.REACT_APP_SYSDIR}/${img.dir}/${img.name}`} alt={`Slide ${i}`} />
                     </figure>
                </div>
                ))}
            </Slider>
            
            <Slider
                className={ images.length > 1 ? "slider-nav" : "slider-nav hidden"  }
                {...settings2}
                ref={slider2}
            >
                {images.map((img, i) => (
                    <div key={img.name}　className={i === currentSlide ? "active-slide" : ""}>
                        <figure>
                        <img src={`${process.env.REACT_APP_SYSDIR}/${img.dir}/${img.name}`} alt={`SlideNav ${i}`} />
                        </figure>
                    </div>
                ))}
            </Slider>
            
        </div>
    );
};

