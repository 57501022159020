import React from "react";
import { useState } from "react";
import { ApartmentForm, LandForm,  HouseForm } from "src/components/Parts/Form/sateiFromParts";
import styled from "styled-components"


export const SateiFromView =  ({onSubmit, register, errors, handleSubmit, setActvieTab}) => {

    const [tab, setTab] = useState(1) 

    const chengeTab = (num) => {
        setTab(num)
        setActvieTab(num)
    }

    return (
        <>
                <SForm className="form" onSubmit={handleSubmit(onSubmit)}>
                <dl>
                    <dt>
                        物件種別
                    </dt>
                    <dd>
                        <STabNav>
                            <li data-tab="1" onClick={() => {chengeTab(1)}} 
                                className={ tab === 1 ? 'active' : ''}>マンション</li>
                            <li data-tab="2" onClick={() => {chengeTab(2)}}
                                className={ tab === 2 ? 'active' : ''}>戸建</li>
                            <li data-tab="3" onClick={() => {chengeTab(3)}}
                                className={ tab === 3 ? 'active' : ''}>土地</li>
                        </STabNav>
                        {
                            tab === 1 &&
                            <ApartmentForm {...{register, errors}}></ApartmentForm>
                        }
                        {
                            tab === 2 && 
                            <HouseForm {...{register, errors}}></HouseForm>
                        }
                        {
                            tab === 3 &&
                            <LandForm {...{register, errors}}></LandForm>
                        }
                    </dd>
                    <dt>
                        物件住所
                    </dt>
                    <dd>
                        <input type="text"  {...register("bukkenAddress",{ required:true })} />
                        <span className={errors.bukkenAddress && "err_on"}>{errors.bukkenAddress && "エラー：未入力"}</span>
                    </dd>
                    <dt>
                        現況
                    </dt>
                    <dd>
                        <input type="text"  {...register("genkyo",{ required:true })} />
                        <span className={errors.genkyo && "err_on"}>{errors.genkyo && "エラー：未入力"}</span>
                    </dd>
                    <dt>
                        備考
                    </dt>
                    <dd>
                        <div className="f-textarea"><textarea  {...register("note")} className="f-hsize-4"></textarea></div>
                    </dd>
                </dl>
                
                <h3>お客様の情報</h3>
                <dl>
                    <dt>
                        お名前
                    </dt>
                    <dd>
                        <input type="text"  {...register("name",{ required:true })} />
                        <span className={errors.name && "err_on"}>{errors.name && "エラー：未入力"}</span>
                    </dd>
                    <dt>
                        メールアドレス
                    </dt>
                    <dd>
                        <input type="text" 
                                    {...register("email",{ required:true, pattern:/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/ })}
                                    className="f-size-7" placeholder="メールアドレス"/>
                        <span id="email_err" className={errors.email && "err_on"}>
                            {errors.email?.type === "required" && "エラー：未入力"}
                            {errors.email?.type === "pattern" && "エラー：形式が違います"}
                        </span>
                    </dd>
                    <dt>
                        電話番号
                    </dt>
                    <dd>
                        <input type="text"  {...register("tel",{ required:true })} />
                        <span className={errors.tel && "err_on"}>{errors.tel && "エラー：未入力"}</span>
                    </dd>
                    <dt>
                        住所
                    </dt>
                     <dd>
                        <input type="text"  {...register("address",{ required:true })} />
                        <span className={errors.address && "err_on"}>{errors.address && "エラー：未入力"}</span>
                    </dd>
                </dl>
                <div className="agree m-t-20">
                    <div className="f-size-auto">
                        <div className="f-checkbox">
                            <input type="checkbox" id="agree" {...register("privacy",{ required:true })}/>
                            <label htmlFor="agree">個人情報の取扱に同意する</label>
                        </div>
                    </div>
                </div>
                <button type="submit" className="indform-submit">送信する</button>
                </SForm>
     
        </>
    )
}

const SForm = styled.form`
dl{
    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
    padding: 14px;
    border: solid 1px #cecccc;
}
dl dt{
    box-sizing: border-box;
    flex-basis: 30%;
    padding: 20px;
    background: #F7F7F7;;
    border-bottom: 2px solid #fff;
    display: flex;
    align-items: center;
    font-weight:bold;
}
dl dd{
    box-sizing: border-box;
    flex-basis: 70%;
    padding: 20px;
    border-bottom: 2px solid #F7F7F7;;
}
h3{
    padding: 8px 14px;
    background: #D2DED0;
    margin: 8px 0;
    
}
@media (max-width:767px){
    dl dt{
        flex-basis: 100%;
    }
    dl dd{
        flex-basis: 100%;
        padding:10px
    }
}
`;

const STabNav = styled.ul`
display: flex;
justify-content: space-between;
margin-bottom: 10px;
li{
    background: #3A738E;
    color: #fff;
    text-align: center;
    flex-basis: 32%;
    padding: 10px;
    cursor: pointer;
    &:hover{
        background: #292AFF;
    }
    &.active{
        background: #292AFF;
    }
}
`;