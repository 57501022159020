import React, { Suspense, useEffect, useState } from "react";
import { Useable } from 'src/utils/Useable';
import { fetchChintaiRecoms } from "src/data/fetchChintai";
import { numFormat } from 'src/utils/textUtils';
import { Link } from "react-router-dom";

const BukkenList = (props) => {
    const {respons, data:chintais } = props.data.getOrThrow();
    
    return (
        <>
            {chintais && 
                chintais.map((c, i) => {
                    let src;
                    c.images[0] ? src = `${process.env.REACT_APP_SYSDIR}/${c.images[0].dir}/${c.images[0].name}` 
                    : src = `${process.env.REACT_APP_DIR}/images/noimg.png`
                    return (
                    <div className="item" key={i}>
                        <div className="topics-bukken-img">
                            <img src={src} />
                        </div>
                        <div className="topics-bukken-info">
                            <h3>{c.name}</h3>
                            <p>
                            家賃 :{numFormat(c.price)}円<br/>
                            {c.search_params.layout}<br/>
                            {c.search_params.city}/{c.search_params.area}
                            </p>
                            <Link to={`/chintaisDetail/${c.id}`} className="btn-bacis">詳しくはこちら</Link>
                        </div>
                    </div>
                    
                    )
                })
            }
        </>
    )
}

export const RecomsChintaiView = () => {

    const [chintais, setChintais] = useState(0)

    useEffect(() => {
        setChintais(new Useable(fetchChintaiRecoms()))
    },[])
    
    return (
        <>
          { chintais !== 0 &&
                <Suspense>
                    <BukkenList {...{data:chintais}}></BukkenList>
                </Suspense>
                }
        </>
    )
}