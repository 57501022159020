import React, { Suspense, useEffect, useState } from "react";
import { Useable } from 'src/utils/Useable';
import { fetchBaibaiRecoms } from "src/data/fetchBaibai";
import { numFormat } from 'src/utils/textUtils';
import { Link } from "react-router-dom";

const BukkenList = (props) => {
    const {respons, data:baibais } = props.data.getOrThrow();
    
    return (
        <>
            {baibais && 
                baibais.map((c, i) => {
                    let src;
                    c.images[0] ? src = `${process.env.REACT_APP_SYSDIR}/${c.images[0].dir}/${c.images[0].name}` 
                    : src = `${process.env.REACT_APP_DIR}/images/noimg.png`
                    return (
                        <div className="item" key={i}>
                            <div className="topics-bukken-img">
                                <img src={src} />
                            </div>
                            <div className="topics-bukken-info">
                                <h3>{c.name}</h3>
                                <p>
                                価格 :{numFormat(c.price)}<br/>
                                {c.address}<br/>
                                {c.search_params.city} /  {c.search_params.layout}
                                </p>
                                <Link to={`/baibaisDetail/${c.id}`} className="btn-bacis">詳しくはこちら</Link>
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}

export const RecomsBaibaiView = () => {

    const [baibais, setbaibais] = useState(0)

    useEffect(() => {
        setbaibais(new Useable(fetchBaibaiRecoms()))
    },[])
    
    return (
        <>
         { baibais !== 0 &&
                <Suspense>
                    <BukkenList {...{data:baibais}}></BukkenList>
                </Suspense>
                }
        </>
    )
}