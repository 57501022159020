import React,{useState} from "react";
import { Link } from "react-router-dom";
import { SlideGallaryParts } from "../Parts/slideGallary/slideGallaryParts";
import parse from 'html-react-parser';
import { textToBr, addComma } from 'src/utils/textUtils';
import { LandColumnParts } from "../Parts/landColumnsParts";
import { BukkenFormView } from "src/components/Views/form/bukkenFormView";
import { ConfirmView } from "src/components/Views/form/confirmView";
import { SlickSliderParts } from "../Parts/slideGallary/slickSliderPrats";

export const LandsDetailView = (props) => {
    
    const {respons, data: detail} = props.data.getOrThrow()

    respons === 'success' ? document.title = `${detail.name} - ${detail.address} | 土地物件  | 有限会社 太洋開発 宮崎県日向市` 
                            : document.title = respons
    

    const [input, setInput] = useState(0)
    const [onConfrim, setOnConfrim] = useState(0)

    return (
        <>
        { respons === 'success' && 
          onConfrim === 0 &&
        <div>
        <section id="detail">
            <div className="detail-inner">
                <Link to={"/lands"} className="pageback">一覧に戻る</Link>
                <h1 className="detail-title-land"><span> {detail.search_params.category}</span> <strong>{detail.name}</strong></h1>
                <div className="detail-headline">
                    <dl>
                        <dt>価格</dt>
                        <dd>{detail.price}</dd>
                    </dl>
                    <dl>
                        <dt>住所</dt>
                        <dd>{detail.address}</dd>
                    </dl>
                </div>
                <div className="detail-main">
                    <div className="visual">
                        { detail.images.length > 0 &&
                        <div className="photos">
                            <SlickSliderParts {...{images:detail.images}}></SlickSliderParts>
                        </div>
                        }
                        <p className="notes">
                        {parse(textToBr(detail.summary))}
                        </p>
                    </div>
                    <div className="columns">
                        <LandColumnParts {...{detail}}></LandColumnParts>
                    </div>
                </div>
            </div>
        </section>
        <section id="photoMap">
            <div className="photoMap-inner">
                <h1 className="photoMap-title-chintai">地図</h1>
    
                <div className="photoMap-map">
                    {parse(detail.gmap)}
                </div>
            </div>
        </section>
        <BukkenFormView {...{setOnConfrim, 
                setInput, 
                dir:'landsDetail', 
                address:detail.address,
                id:detail.id, 
                name:detail.name}}>    
                </BukkenFormView>
        </div>
        }
           { respons === 'success'  &&  
            onConfrim === 1 &&
            <section id="indform">
            <h1 className="indform-title-chintai">
                お問い合わせフォーム
            </h1>
            <ConfirmView {...{input, setOnConfrim, type:'bukken'}}></ConfirmView>
            </section>
        }
        </>
    )
}