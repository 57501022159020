import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import axios from 'axios'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { RecomsChintaiView } from "../Views/index/recomsChintaiVeiw";
import { RecomsLandView } from "../Views/index/recomsLandVeiw";
import { RecomsBaibaiView } from "../Views/index/recomsBaibaiVeiw";
import { Link } from "react-router-dom";
import { fetchIndexNew } from 'src/data/fetchIndex'
import { numFormat } from 'src/utils/textUtils';

export const TopPage = () => {

     //sldier 
    const settings = {
        dots: true,
        infinite: true,
        speed: 1200,
        autoplay:true,
        arrows:true,
        fade:true
      };

    const images = [
        `${process.env.REACT_APP_DIR}images/slide01.jpg?2`, 
        `${process.env.REACT_APP_DIR}images/slide02.jpg?2`,
        `${process.env.REACT_APP_DIR}images/slide03.jpg?2`]

    const [posts, setPosts] = useState([]);
    const [newBukkens, setNewBukkens] = useState(0);
    useEffect(() => {
        async function fetchPosts() {
            try {
                const response = await axios.get('https://taiyo-vis.jp/news/wp-json/wp/v2/posts');
                setPosts(response.data);
            } catch (error) {
                console.error(error);
            }
        }
        fetchPosts();
        fetchIndexNew().then((res) => {
            if(res.data.length > 7){
                res.data.length = 9
            }
            setNewBukkens(res.data)
        } )

    }, []);

    const dateConvert  = (date) => {
        const dateData = new Date(date);
		

        return `${dateData.getFullYear()}/${(dateData.getMonth()+1).toString().padStart(2, '0')}/${dateData.getDate()}`
    }

    const htmlDecode = (title) => {
        const parser = new DOMParser();
    }

    function formatDateToJapanese(dateString) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString('ja-JP', options);
        return formattedDate;
      }

    return (
        <>
        <section id="main">
            <div className="main-inner">
             <Slider {...settings}>
                {   images.map((img,i)=>{
                    return(
                        <div key={i}>
                            <img src={img} alt="pictuer" />
                        </div>
                        )
                    })
                }
            </Slider>
            </div>
        </section>

        <section id="bunner">
            <div className="bunner-inner">
                <ul className="bunner-list">
                    <li><Link to="baibais"><img src="./images/kaitai.png" alt="買いたい"/></Link></li>
                    <li><Link to="chintais"><img src="./images/karitai.png" alt="借りたい"/></Link></li>

                    <li><Link to="kaitori"><img src="./images/uritai.png" alt="売りたい"/></Link></li>
                    <li><Link to="satei"><img src="./images/satei.png" alt="査定したい"/></Link></li>
                </ul>
            </div>
        </section>

        <section id="topics">
            <div className="topics-inner">
                <div className="topics-bukken">
                    {newBukkens !== 0 &&
                    
                    <>
                        <h3 className="content-title">新着物件</h3>
                        <ul className="news-bukken-list">

                        {   
                            
                            newBukkens.map((n,i) => {
                                if(n.type == 'chintai'){
                                    return (
                                        <li key={`${i}-${n.bukken.id}`}>
                                            <Link to={`/chintaisDetail/${n.bukken.id}`}>
                                               <span className="date">公開日：{formatDateToJapanese(n.bukken.created)}</span>
                                               <span className="chintai">賃貸</span> <b>{n.bukken.name}</b>  {n.bukken.search_params.layout}/家賃{numFormat(n.bukken.price)}円
                                            </Link>
                                        </li>
                                    )
                                }else if(n.type == 'land'){
                                    return (
                                        <li key={`${i}-${n.bukken.id}`}>
                                            <Link to={`/landsDetail/${n.bukken.id}`}>
                                                <span className="date">公開日：{formatDateToJapanese(n.bukken.created)}</span>
                                                <span className="land">土地</span> <b>{n.bukken.name}</b>
                                            </Link>
                                        </li>
                                    )
                                }else if(n.type == 'baibai'){
                                    return (
                                        <li key={`${i}-${n.bukken.id}`}>
                                            <Link to={`/baibaisDetail/${n.bukken.id}`}>
                                                <span className="date">公開日：{formatDateToJapanese(n.bukken.created)}</span>
                                                <span className="baibai">{n.bukken.search_params.category}</span> <b>{n.bukken.name}</b> 
                                            </Link>
                                        </li>
                                    )
                                }
                                
                                
                            })
                        }
                        </ul>
                    </>
                    }
                     {posts.length > 0 &&
                        <>
                        <h3 className="content-title">新着情報</h3>
                        <ul className="topics-bukken-list">
                        {
                        posts.map((post) => (
                           
                            <li key={post.id}>
                                <a href={post.acf?.bukken_link ? post.acf.bukken_link : post.link }>
                                    {dateConvert(post.date)}　{post.title.rendered}
                                </a>
                            </li>
                        ))
                        }
                        </ul>
                        </>
                    }
                    
                </div>
                
                <div className="topics-panel">
                    <h3 className="content-title">物件検索</h3>
                    <ul className="topics-panel-list">
                        <li className="chintai"><Link to="chintais">賃貸</Link></li>
                        <li className="land"><Link to="lands">土地</Link></li>
                        <li className="baibai"><Link to="baibais">中古・新築</Link></li>
                        <li className="info"><Link to="infomation">お問合せ</Link></li>
                        <li className="satei"><Link to="satei">無料査定</Link></li>
                        <li className="kaitori"><Link to="kaitori">買取</Link></li>
                        <li className="blog"><a href="http://www.hyuga.jp/blog/page/taiyo" target="_blank">ブログ</a></li>
                        <li className="insta"><a href="https://www.instagram.com/taiyokaihatsu/?hl=ja" target="_blank">instagram</a></li>
                    </ul>
                </div>
            </div> 
        </section>
        

        <section id="recommend">
            <h1>おすすめ物件</h1>
            <div className="topics-inner">
                <div className="topics-bukken-slide">
                    <h3 className="content-title baibai">新築・中古</h3>
                    <RecomsBaibaiView></RecomsBaibaiView>
                </div>
                <div className="topics-bukken-slide">
                    <h3 className="content-title land">土地</h3>
                    <RecomsLandView></RecomsLandView>
                </div>
                <div className="topics-bukken-slide">
                    <h3 className="content-title chintai">賃貸</h3>
                    <RecomsChintaiView></RecomsChintaiView>
                </div>
            </div>
        </section>
        </>
    )
}