import React,{useState} from "react";
import { SlideGallaryParts } from "../Parts/slideGallary/slideGallaryParts";
import parse from 'html-react-parser';
import { textToBr, addComma } from 'src/utils/textUtils';
import { formattedYearMonth } from 'src/utils/dateTime';
import { BaibaiColumnsParts } from "../Parts/baibaiColumnsParts";
import { Link } from "react-router-dom";
import { BukkenFormView } from "src/components/Views/form/bukkenFormView";
import { ConfirmView } from "src/components/Views/form/confirmView";
import { SlickSliderParts } from "../Parts/slideGallary/slickSliderPrats";

export const BaibaisDetailView = (props) => {
    
    const {respons, data: detail} = props.data.getOrThrow()
    
    if(respons === 'success'){
            document.title = `${detail.name} - ${detail.address} | 売買物件  | 有限会社 太洋開発 宮崎県日向市`
    }else{
        document.title = respons
    }

    const [input, setInput] = useState(0)
    const [onConfrim, setOnConfrim] = useState(0)
    
    return (
        <>
         { respons === 'success'  &&  
            onConfrim === 0 &&
            <div>
                
                <section id="detail">
                    <div className="detail-inner">
                        <Link to={"/baibais"} className="pageback">一覧に戻る</Link>
                        <h1 className="detail-title-baibai"><span> {detail.search_params.category}</span> <strong>{detail.name}</strong></h1>
                        <div className="detail-headline">
                            <dl>
                                <dt>価格</dt>
                                <dd>{detail.price}</dd>
                            </dl>
                            <dl>
                                <dt>住所</dt>
                                <dd>{detail.address}</dd>
                            </dl>
                        </div>
                        <div className="detail-main">
                            <div className="visual">
                                <div className="photos mb-20">
                                { detail.images.length > 0 &&
                                    <SlickSliderParts {...{images:detail.images}}></SlickSliderParts>
                                }
                                </div>
                                <div className="photos">
                                { detail.madoris.length > 0 &&
                                    <SlickSliderParts {...{images:detail.madoris}}></SlickSliderParts>
                                }
                                </div>
                                <p className="notes">
                                    {parse(textToBr(detail.summary))}
                                </p>
                            </div>
                            <div className="columns">
                                <BaibaiColumnsParts {...{detail}}></BaibaiColumnsParts>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="photoMap">
                    <div className="photoMap-inner">
                        <h1 className="photoMap-title-chintai">地図</h1>
            
                        <div className="photoMap-map">
                            {parse(detail.gmap)}
                        </div>
                    </div>
                </section>
                <BukkenFormView {...{setOnConfrim, 
                setInput, 
                dir:'baibaisDetail', 
                address:detail.address,
                id:detail.id, 
                name:detail.name}}>    
                </BukkenFormView>
            </div>
        }
         { respons === 'success'  &&  
            onConfrim === 1 &&
            <section id="indform">
            <h1 className="indform-title-chintai">
                お問い合わせフォーム
            </h1>
            <ConfirmView {...{input, setOnConfrim, type:'bukken'}}></ConfirmView>
            </section>
        }
        </>
    )
}