import React, { Suspense, useEffect, useState } from "react";
import { Useable } from 'src/utils/Useable';
import { fetchChintaiInfo } from "src/data/fetchChintai";
import { Loader } from "src/components/Parts/loaderParts";
import { ChintaisDetailView } from "src/components/Views/chintaisDetailView";
import { useParams } from 'react-router-dom';

export const ChintaisDetailPage = () => {
    document.body.id = "contents";
    const { id } = useParams()
    const [detail, setDetail] = useState(0)
    
    useEffect(() => {
        setDetail(new Useable(fetchChintaiInfo(id)))
    },[])

    return (
        <section id="contents" className="contents">
            <div className="chitnais-inner">
            <h1 className="pagetitle-chintai">
                賃貸物件を探す
            </h1>
            </div>
            {detail && 
            <Suspense fallback={<Loader></Loader>}>
                <ChintaisDetailView {...{data:detail}}></ChintaisDetailView>
            </Suspense>
            }
         
        </section>
    )
}