import React from "react";

export const CompanyPage = () => {
    document.title = '会社概要 | 有限会社 太洋開発 宮崎県日向市'
    return (
        <section id="contents">
        <div className="company-inner">
            <h1 className="pagetitle-comapny">
                会社概要
            </h1>
            <div className="comapany-overview">
                <figure className="company-photo">
                    <img src={`${process.env.REACT_APP_DIR}images/company_01.jpg`}/>
                </figure>
                <table className="comapany-table">
                    <tbody>
                    <tr>
                        <th>
                            会社名
                        </th>
                        <td>
                            有限会社　太洋開発
                        </td>
                    </tr>
                    <tr>
                        <th>
                            所在地
                        </th>
                        <td>
                            日向市大字日知屋字塩田１６２９３番９
                        </td>
                    </tr>
                    <tr>
                        <th>
                            代表者
                        </th>
                        <td>
                            代表取締役　西 村 光 平
                        </td>
                    </tr>
                    <tr>
                        <th>
                            設立年月日
                        </th>
                        <td>
                            昭和５９年３月１８日
                        </td>
                    </tr>
                    <tr>
                        <th>
                            加 盟 団 体
                        </th>
                        <td>
                            宮崎県宅地建物取引業協会
                            <br/>全国宅地建物取引業保証協会
                            <br/>九州不動産公正取引協議会
                            <br/>宮崎地方本部宅地建物取引委員会 
                            <br/>（一社）全国賃貸不動産管理業協会
                        </td>
                    </tr>
                    <tr>
                        <th>
                            加盟流通機構
                        </th>
                        <td>
                            社団法人　宮崎県不動産流通センター
                        </td>
                    </tr>
                    <tr>
                        <th>
                            業　　　種
                        </th>
                        <td>
                            宅地建物取引業
                        </td>
                    </tr>
                    <tr>
                        <th>
                            業 務 内 容
                        </th>
                        <td>
                            土地建物売買（仲介・買取販売）賃貸（仲介・管理）
                        </td>
                    </tr>
                    <tr>
                        <th>
                            宅地建物取引免許
                        </th>
                        <td>
                            宮崎県知事（７）３９６７
                        </td>
                    </tr>
                    <tr>
                        <th>
                            取引金融機関
                        </th>
                        <td>
                            (株)宮崎銀行・(株)宮崎太陽銀行・(株)高鍋信用金庫<br/>
                            (株)鹿児島銀行・日向農業協同組合
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
           
            <div className="company-map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13470.966858732721!2d131.6414323!3d32.426076!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbd3d68f1231852c!2z5pyJ6ZmQ5Lya56S-IOWkqua0i-mWi-eZug!5e0!3m2!1sja!2sjp!4v1675661468348!5m2!1sja!2sjp" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>

    </section>
    )
}