import React, { Suspense, useEffect, useState } from "react";
import { Useable } from 'src/utils/Useable';
import { fetchChintaiIndex } from "src/data/fetchChintai";
import { Loader } from "src/components/Parts/loaderParts";
import { ChintaisIndexView } from "../Views/chintaisIndexView";


export const ChintaisPage = () => {
    document.title = '賃貸物件 | 有限会社 太洋開発 宮崎県日向市'
    const [chintais, setChintais] = useState(0)

    useEffect(() => {
        setChintais(new Useable(fetchChintaiIndex()))
    },[])

    return (
        <>
        <section id="contents" className="contents">
                <div className="chitnais-inner">
                <h1 className="pagetitle-chintai">
                    賃貸物件を探す
                </h1>
                {chintais && 
                <Suspense fallback={<Loader></Loader>}>
                    <ChintaisIndexView {...{data:chintais}}></ChintaisIndexView>
                </Suspense>
                }
            </div>
        </section>
        </>
    )
}