import React, { Suspense, useEffect, useState } from "react";
import { Useable } from 'src/utils/Useable';
import { fetchLandRecoms } from "src/data/fetchLand";
import { numFormat } from 'src/utils/textUtils';
import { Link } from "react-router-dom";

const BukkenList = (props) => {
    const {respons, data:lands } = props.data.getOrThrow();
    
    return (
        <>
            {lands && 
                lands.map((c, i) => {
                    let src;
                    c.images[0] ? src = `${process.env.REACT_APP_SYSDIR}/${c.images[0].dir}/${c.images[0].name}` 
                    : src = `${process.env.REACT_APP_DIR}/images/noimg.png`
                    return (
                        <div className="item" key={i}>
                            <div className="topics-bukken-img">
                                <img src={src} />
                            </div>
                            <div className="topics-bukken-info">
                                <h3>{c.name}</h3>
                                <p>
                                価格 :{numFormat(c.price)}<br/>
                                {c.address}<br/>
                                {c.search_params.city} / {c.search_params.area}
                                </p>
                                <Link to={`/landsDetail/${c.id}`} className="btn-bacis">詳しくはこちら</Link>
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}

export const RecomsLandView = () => {

    const [lands, setlands] = useState(0)

    useEffect(() => {
        setlands(new Useable(fetchLandRecoms()))
    },[])
    
    return (
        <>
          { lands !== 0 &&
            <Suspense>
                <BukkenList {...{data:lands}}></BukkenList>
            </Suspense>
            }
        </>
    )
}