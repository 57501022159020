import React from "react";
import { BrowserRouter, Routes, Route} from "react-router-dom";
import { TopPage } from "./components/Pages/topPage";
import { ChintaisPage } from "src/components/Pages/chintaisPage"
import { LandsPage } from "./components/Pages/landsPage";
import { BaibaisPage } from "./components/Pages/baibaisPage";
import { BaibaisDetailPage } from "./components/Pages/baibaisDetailPage"
import { LandsDetailPage } from "./components/Pages/landsDetailPage"
import { ChintaisDetailPage } from "./components/Pages/chintaisDetailPage"
import { ContacPage } from "./components/Pages/contactPage";
import { CompanyPage } from "./components/Pages/companyPage";
import { SateiPage } from "./components/Pages/sateiPage";
import { KateiPage } from "./components/Pages/kaitoriiPage";
 
function App() {
  return(
    <BrowserRouter basename={process.env.REACT_APP_DIR}>  
      <Routes>
        <Route path={`/`} element={
          <TopPage />
        } />
        <Route path={`/chintais`} element={
          <ChintaisPage />
        } />
        <Route path={`/chintaisDetail/:id`} element={
           <ChintaisDetailPage/>
        } />
        
        <Route path={`/lands`} element={
          <LandsPage />
        } />
        <Route path={`/landsDetail/:id`} element={
           <LandsDetailPage></LandsDetailPage>
        } />


        <Route path={`/baibais`} element={
          <BaibaisPage />
        } />
        <Route path={`/baibaisDetail/:id`} element={
          <BaibaisDetailPage />
        } />
           <Route path={`/infomation`} element={
          <ContacPage />
        } />
         <Route path={`/company`} element={
          <CompanyPage />
        } />
         <Route path={`/satei`} element={
          <SateiPage/>
        } />
          <Route path={`/kaitori`} element={
          <KateiPage/>
        } />
      </Routes>
     
    </BrowserRouter>
  )
}

export default App;